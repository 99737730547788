<template>
  <div class="page-header-mask">
    <div class="left-pages">
      <a :href="homeUrl">
        <img :draggable="false" class="logo isMaskEx" :src="logImg" alt />
      </a>
      <ul class="pages-routes" v-if="showHeader">
        <a v-if="trade_tab != 1 && !noLogin" @click="goQuicklycoins">
          <li :class="active == 7 ? 'active' : ''">
            <a>{{ translateTitle("购买货币") }}</a>
          </li>
        </a>
        <a :href="routeSplicing(language, `markets`)">
          <li :class="active == 1 ? 'active' : ''">
            <a :href="routeSplicing(language, `markets`)">{{
              translateTitle("行情")
            }}</a>
          </li>
        </a>

        <a :href="routeSplicing(language, 'exchange/btc_usdt')">
          <li :class="active == 2 ? 'active' : ''">
            <a :href="routeSplicing(language, 'exchange/btc_usdt')">{{
              translateTitle("币币交易")
            }}</a>
          </li>
        </a>
        <a
          :href="routeSplicing(language, `contract-exchange/btcusdt`)"
          v-if="!isHideLever && trade_tab != 1 && !noLogin && showTab"
        >
          <li :class="active == 3 ? 'active' : ''">
            <a :href="routeSplicing(language, `contract-exchange/btcusdt`)">{{
              translateTitle("U本位合约")
            }}</a>
          </li>
        </a>
        <a
          :href="routeSplicing(language, `delivery/ethusd`)"
          v-if="!isHideLever && trade_tab != 1 && !noLogin && showTab"
        >
          <li :class="active == 6 ? 'active' : ''">
            <a :href="routeSplicing(language, `delivery/ethusd`)">{{
              translateTitle("币本位合约")
            }}</a>
          </li>
        </a>
        <a
          :href="routeSplicing(language, `finances`)"
          v-if="finance_tab != 1 && !noLogin && showTab"
        >
          <li :class="active == 4 ? 'active' : ''">
            <a :href="routeSplicing(language, `finances`)">{{
              translateTitle("金融理财")
            }}</a>
          </li>
        </a>
        <li
          :class="[active == 8 ? 'active' : '', { menuTop: companyBoolean }]"
          @mouseenter="companyBoolean = !companyBoolean"
          @mouseleave="companyBoolean = !companyBoolean"
        >
          <a :href="routeSplicing(language, `company/aboutus`)">{{
            translateTitle("公司")
          }}</a>
          <transition name="fade">
            <ul v-show="companyBoolean" class="maskMenu">
              <a :href="routeSplicing(language, `company/aboutus`)"
                ><li>{{ translateTitle("关于我们") }}</li></a
              >
              <a :href="routeSplicing(language, `company/careers`)"
                ><li>{{ translateTitle("招贤纳士") }}</li></a
              >
            </ul>
          </transition>
        </li>

        <!-- <a
          href="https://bitda.zendesk.com/hc/zh-cn"
          target="_blank"
          v-if="isbitda"
        >
          <li>
            <a href="https://bitda.zendesk.com/hc/zh-cn" target="_blank">{{
              translateTitle("帮助中心")
            }}</a>
          </li>
        </a> -->
        <a  target="_blank">
          <li :class="active == 5 ? 'active' : ''" class='add-new-class'>
            <a>{{ translateTitle("博客") }}</a>
            <span v-if="isZh">(即将推出)</span>
            <span v-else>(Coming Soon)</span>
          </li>
        </a>
      </ul>
    </div>
    <div class="right-menus">
      <span
        class="station-name"
        v-if="app_env == 'test' || app_env == undefined"
      >
        {{ envName }}
      </span>

      <el-menu class="right-menu" mode="horizontal" @select="handleSelect">
        <template>
          <!-- 个人中心 -->
          <user-menu></user-menu>
        </template>
        <!-- <template>
          <a href="/letter">
            <li class="letter-center">
              <el-badge class="item" is-dot :hidden="true">
                <img src="../../assets/img/letter.png" alt="" />
              </el-badge>
              <div class="driver"></div>
            </li>
          </a>
        </template> -->
        <template>
          <!-- 注册 -->
          <!-- <el-menu-item class="nav-menu-item login" v-show="!userInfoShow">
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'login')"
              >{{ translateTitle("登录") }}</router-link
            >
          </el-menu-item> -->
          <!-- 注册 -->
          <!-- <el-menu-item
            class="nav-menu-item register theme-btn"
            v-show="!userInfoShow"
          >
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'register')"
              >{{ translateTitle("注册") }}</router-link
            >
            <div class="driver"></div>
          </el-menu-item> -->

        </template>
        <template v-if="false">
          <li
            class="download-app"
            @mouseenter="onShowDownLoad"
            @mouseleave="onHiddenDownLoad"
          >
            <span class="driver"></span>
            <span class="download-app-text">
              <i class="iconfont iconicon-hd-3"></i>
              {{ translateTitle("APP下载") }}</span
            >
            <div v-show="showDownLoadImg" class="down-box">
              <div>
                <p class="down-load-text">
                  {{ translateTitle("扫码下载") }}
                </p>
                <div class="down-load-content">
                  <div>
                    <p class="text-title">iOS</p>
                    <img :src="appstore_url" alt="" />
                    <p>
                      <span class="down-load-text">
                        <img
                          class="icon-title"
                          draggable="false"
                          src="../../assets/img/apple.png"
                          alt=""
                        />
                        {{ translateTitle("iPhone下载") }}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p class="text-title">Android</p>
                    <img :src="google_play_url" alt="" />
                    <p>
                      <span class="down-load-text">
                        <img
                          class="icon-title"
                          draggable="false"
                          src="../../assets/img/andriod.png"
                          alt=""
                        />
                        {{ translateTitle("Android下载") }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div v-else>
                <p class="down-load-text">
                  {{ translateTitle("扫码下载iOS&Android APP") }}
                </p>
                <span class="img-content">
                  <p class="left-item">
                    <span class="down-load-item-text">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img/apple.png"
                        alt=""
                      />
                      iPhone
                    </span>
                    <span class="down-load-item-text V2">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img/andriod.png"
                        alt=""
                      />
                      Android
                    </span>
                  </p>
                  <img class="down-load-url" :src="appDownLoadImg" alt="" />
                </span>
              </div> -->
            </div>
          </li>
        </template>
        <!-- 法币 -->

        <el-submenu
          class="select-fiat"
          popper-class="sub-item-wrap  select-fiat-currency"
          index="6"
          :collapse-transition="false"
          :popper-append-to-body="true"
        >
          <template slot="title" class="lang">
            <router-link class="lang" to="#">
              {{ currency }}
            </router-link>
          </template>
          <el-menu-item
            class="sub-item"
            v-for="(item, index) in currencyMaps"
            :key="index"
            :index="item.logic_name"
            @click="onCurrencyChange(item.logic_name)"
          >
            <span>
              <img :src="item.icon" />
              {{ item.abbreviate }}
            </span>
          </el-menu-item>
        </el-submenu>
        <!-- 语言 -->
        <li
          class="el-submenu sub-item-wrap select-lang-mask"
          @click="onMaskLangDialog"
        >
          <img :src="imgUrl" />
          <span>
            {{ languageName }}
          </span>
        </li>
        <!-- 白夜版切换 -->
        <!-- <li
          v-if="is8V"
          class="el-submenu sub-item-wrap select-lang-mask"
          style="margin-left:10px;margin-right:10px;"
          @click="onChangeStyle"
        >
          <span>
            <i
              class="iconfont"
              :class="darkMode == 1 ? 'iconbai' : 'iconhei'"
            ></i>
          </span>
        </li> -->
      </el-menu>
    </div>

    <el-dialog custom-class="langVisible" :visible.sync="langVisible">
      <ul>
        <li
          v-for="item in countryList"
          :key="item.value"
          @click="onLangClick(item)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import QrcodeVue from "qrcode.vue";
import UserMenu from "./UserMenu";
// import companyMenu from "./companyMenu";
import { apiGetStaticTableInfo } from "@/model/settings";
// const { VUE_APP_ID, VUE_APP_APP } = process.env;
import zhCN from "../../assets/img/lang/f-cn.png";
import zhHK from "../../assets/img/lang/f-hk.png";
import enUS from "../../assets/img/lang/f-en.png";
import Ja from "../../assets/img/lang/f-ja.png";
import Ko from "../../assets/img/lang/f-ko.png";

import ar from "../../assets/img/lang/f-ar.png";
import fr from "../../assets/img/lang/f-fr.png";
import es from "../../assets/img/lang/f-es.png";
import ru from "../../assets/img/lang/f-ru.png";
import de from "../../assets/img/lang/f-de.png";
import pt from "../../assets/img/lang/f-pt.png";
import hi from "../../assets/img/lang/f-hi.png";
import vi from "../../assets/img/lang/f-vi.png";
import tl from "../../assets/img/lang/f-tl.png";
import th from "../../assets/img/lang/f-th.png";
import tr from "../../assets/img/lang/f-tr.png";
import it from "../../assets/img/lang/f-it.png";
import { VUE_APP_ENV } from "@/assets/js/stationSetting";
import { getLangPackage } from "@/assets/js/lang";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    UserMenu,
  },
  data() {
    return {
      isZh:localStorage.getItem('language') === 'zh_CN' || localStorage.getItem('language') === 'zh_HK',
      companyBoolean: false,
      homeUrl: "",
      app_env: "",
      languageName: "English",
      currency: "USD",
      appDownLoadImg: "",
      appstore_url: "",
      tipUrl: "",
      google_play_url: "",
      langVisible: false,
      showDownLoadImg: false,
      // showTab:localStorage.getItem('isALQ') === 'no',
      showTab:false,
      // isMaskEx: false,
      // is8V: false,
      // isbitda: false,
      // isUEEx: false,
      currencyMaps: {},
      logImg: require("../../assets/img/mask-logo.png"),
      imgUrl: require("../../assets/img/en.png"),
      countryList: [
        {
          name: "English",
          value: "en",
          img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          img: fr,
        },
        {
          name: "Español",
          value: "es",
          img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          img: de,
        },
        {
          name: "Português",
          value: "pt",
          img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          img: th,
        },
        {
          name: "Türk",
          value: "tr",
          img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          img: it,
        },
        {
          name: "عربي",
          value: "ar",
          img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          img: zhHK,
        },
      ],
      noLogin: true,
      trade_tab: "",
      finance_tab: "",
    };
  },
  created() {
    this.noLogin = !sessionStorage.getItem("userInfo");
    this.trade_tab =
      sessionStorage.getItem("hidden") &&
      JSON.parse(sessionStorage.getItem("hidden"))["trade_tab"]
        ? JSON.parse(sessionStorage.getItem("hidden"))["trade_tab"]
        : "0";
    this.finance_tab =
      sessionStorage.getItem("hidden") &&
      JSON.parse(sessionStorage.getItem("hidden"))["finance_tab"]
        ? JSON.parse(sessionStorage.getItem("hidden"))["finance_tab"]
        : "0";
    this.homeUrl = `/${this.language}`;
    this.app_env = VUE_APP_ENV;
    // this.getCurrencyMap();

    this.logImg = require("../../assets/img/mask-logo.png");
    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    this.appDownLoadImg = tempUrl.url;
    this.appstore_url = tempUrl.appstore_url;
    this.google_play_url = tempUrl.google_play_url;

    let currency = localStorage.getItem("currency") || "USD";
    let tempLang = localStorage.getItem("language");
    if (tempLang) {
      this.countryList.forEach(ele => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    } else {
      tempLang = "en";

      this.countryList.forEach(ele => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    }

    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }

    this.handleSelect(currency);
  },
  mounted() {
    // let tempList = JSON.parse(localStorage.getItem("settingList"));
    // if (tempList) {
    //   this.currencyMaps = tempList[7];
    // }

    this.getStaticChainInfo();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),

    envName() {
      let tempVal = localStorage.getItem("environment");
      return tempVal;
    },
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    active() {
      let val = 0;
      if (this.$route.path.includes("/markets")) {
        val = 1;
      } else if (this.$route.path.includes("/exchange")) {
        val = 2;
      } else if (this.$route.path.includes("/contract-exchange")) {
        val = 3;
      } else if (this.$route.path.includes("/finances")) {
        val = 4;
      } else if (this.$route.path.includes("/blog")) {
        val = 5;
      } else if (this.$route.path.includes("/delivery")) {
        val = 6;
      } else if (this.$route.path.includes("/quicklycoins")) {
        val = 7;
        // /aboutus/maskEx
      } else if (this.$route.path.includes("/aboutus/maskEx")) {
        val = 8;
      } else {
        val = 0;
      }
      return val;
    },
    showHeader() {
      let val = true;
      if (this.$route.path.includes("/landingpage/register")) {
        val = false;
      } else {
        val = true;
      }
      return val;
    },
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    menuHover(el) {},
    async getStaticChainInfo() {
      await apiGetStaticTableInfo(7).then(({ data }) => {
        this.currencyMaps = data;
      });
    },
    onChangeStyle() {
      if (this.darkMode == 1) {
        this.changeDarkMode(2);
      } else {
        this.changeDarkMode(1);
      }
    },
    async getCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.currencyMaps = JSON.parse(JSON.stringify(data));
      }
    },
    goQuicklycoins() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "quicklycoins"),
        });
      }
    },

    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
    },
    handleSelect(key) {
      let command = "zh_CN";
      if (key == "zh_CN") {
        command = "zh_CN";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "zh_HK") {
        command = "zh_HK";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "en") {
        command = "en";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ja") {
        command = "ja";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ko") {
        command = "ko";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onMaskLangDialog() {
      this.langVisible = true;
    },
    onLangClick(item) {
      this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
      getLangPackage([item.value]);
      this.languageName = item.name;
      // this.$i18n.locale = item.value;
      this.changeLanguage(item.value);
      this.langVisible = false;

      // let href = window.location.href;
      // let n = href.lastIndexOf("/");
      // let lang = href.substr(2, n + 1);
      let pathname = window.location.pathname;
      if (pathname.indexOf("/landingpage/register") != -1) {
        window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}/${this.language}`;
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}/${this.language}`;
      }
      // window.location.reload();
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
  },
};
</script>
<style lang="scss">
.menuTop {
  // background-color: #fff !important;
}
.maskMenu {
  position: absolute;
  width: 170px;
  height: 109px;
  left: 0px;
  top: 60px;
  z-index: 1;
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  a {
    display: block !important;
    background: #ffffff;
    text-align: center;
    height: 50px !important;
  }
  li {
    &:hover {
      color: #15beb4 !important;
    }
    display: inline;
    color: #15192a !important;
  }
}
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.zh_CN {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 120px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.zh_HK {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 120px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.hi {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 145px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.it {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 183px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.de {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 182px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.vi {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 193px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.tr {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 141px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.tl {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.ru {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.th {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.pt {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 162px;
    }
    .page-header-mask .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}
.en {
  .page-header-mask {
    min-width: 1468px;
    .right-menus .el-menu .download-app {
      width: 170px;
    }
  }
  .page-header-mask .right-menus .el-menu .download-app .down-box {
    width: 290px;
  }
  .page-header-mask
    .right-menus
    .el-menu
    .el-submenu
    .el-menu--horizontal
    .el-menu {
    width: 195px !important;
  }
}
.fr {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 221px;
    }
  }
}
.es {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 247px;
    }
  }
}
.ko {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 144px;
    }
  }
}
.ja {
  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 202px;
    }
  }
  .page-header-mask .right-menus .el-menu .download-app .down-box {
    width: 280px;
    height: 166px;
  }
  .page-header-mask
    .right-menus
    .el-menu
    .el-submenu
    .el-menu--horizontal
    .el-menu {
    width: 180px !important;
  }
}
.ar {
  .page-header-mask {
    direction: ltr !important;
  }

  .page-header-mask {
    .right-menus .el-menu .download-app {
      width: 130px;
    }
  }
  .user-center-menu.el-menu-item.nav-menu-item.login
    .el-menu--horizontal.sub-item-wrap.select-user-item
    .el-menu
    .el-menu-item {
    text-align: right;
  }
}
</style>
<style lang="scss">
/* yuan language innerBody */
//修改Lang宽度
.select-lang-mask {
  height: 60px;
  line-height: 60px;
  opacity: 0.6;
  cursor: pointer;
  color: #fff;
  &:hover {
    opacity: 1;
  }
  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  span {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.el-menu--horizontal.sub-item-wrap.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
  top: 59px !important;
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    width: 130px !important;
    min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

//修改货币宽度
.el-menu--horizontal.sub-item-wrap.select-fiat-currency {
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    // width: 130px !important;
    // min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      &:hover {
        background-color: #f3f7fc;
      }
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}
.select-fiat-currency {
  top: 59px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}

/* yuan language innerBody */
.page-header-mask {
  user-select: none;
  // overflow: hidden;
  // height: 100%;
  height: 60px;
  background-color: #12161c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 3px solid transparent !important;
  .logo {
    width: 130px;
    height: 30px;
    min-width: 120px;
    &.isMaskEx {
      width: 122px;
      height: 24px;
    }
  }

  .langVisible {
    z-index: 999;
    ul {
      min-width: 700px;
      margin-left: 20px;
    }

    li {
      cursor: pointer;
      display: inline-block;
      width: 25%;
      padding: 10px 10px;
      &:hover {
        color: black;
      }
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .left-pages,
  .right-menus,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // 左侧
  .left-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .pages-routes {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 45px;
      white-space: nowrap;
      li {
        color: #fff;
        height: 60px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        padding: 0 10px;
        text-align: center;
        // min-width: 110px;
        line-height: 60px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #acb9db;

        a {
          color: #fff;
          font-size: 14px;
          font-family: DINPro-Regular;
          text-decoration: none;
          display: inline-block;
          height: 100%;
        }
        &.menuTop:hover {
          a {
            color: #15beb4 !important;
          }
        }
        &:hover {
          color: #fff;
          a {
            color: #fff;
            opacity: 1;
          }
        }
        &.active {
          a {
            color: #32c8a4;
          }
          border-bottom: 3px solid transparent !important;
        }
      }

      .normal-link {
        padding: 0px;

        a {
          padding: 0 20px;
        }
      }
    }
  }

  .app-link {
    font-size: 14px;
  }

  // 右侧
  .right-menus {
    margin-right: 23px;
    height: 60px;
    min-width: 530px;
    .customer-service {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
    .station-name {
      color: red;
      font-size: 24px;
      margin-right: 10px;
    }
    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #acb9db;
      margin-top: 2px;
    }

    .el-menu {
      background-color: #12161c;
      text-align: left;
      // .channel-header-wrap {
      //   width: 156px !important;
      // }
      // .salary-header-warp {
      //   width: 156px !important;
      // }
      .letter-center {
        float: left;
        width: 40px;
        line-height: 22px;
        margin-top: 20px;
        position: relative;
        img {
          width: 22px;
          height: 22px;
          top: 0px;
          cursor: pointer;
        }
        .driver {
          position: absolute;
          background-color: rgb(172, 185, 219, 0.3);
          right: 0px;
          top: -2px;
          width: 1px;
          height: 30px;
        }
      }
      .download-app {
        // display: flex;
        float: left;
        position: relative;
        width: fit-content;
        height: 60px;
        outline: none;
        border-radius: 4px;
        // border-left: 1px solid #acb9db;

        .download-app-text {
          display: block;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          opacity: 0.6;
          line-height: 60px;
          padding: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            // border-bottom: 4px solid #c61b3d;
            // background-color: rgba(40, 41, 45, 1);
            color: #ffffff;
            opacity: 1;
          }
        }
        .down-box {
          box-sizing: border-box;
          width: 265px;
          // height: 150px;
          padding: 18px 30px 18px 19px;
          border-radius: 4px;
          box-shadow: 0 2px 10px 0 rgba(53, 64, 82, 0.1);
          background-color: #ffffff;
          display: block;
          position: relative;
          z-index: 3000;
          .down-load-text {
            color: #9aa1b1;
            font-size: 12px;
            font-weight: 500;
            .icon-title {
              width: 16px;
              height: 16px;
              vertical-align: text-top;
            }
          }
          .down-load-content {
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            text-align: center;
            .text-title {
              margin-bottom: 10px;
            }
            img {
              width: 70px;
              height: 70px;
            }
          }
          .img-content {
            display: flex;
            margin-top: 24px;
            .qr_code_canvas {
              margin-top: -10px;
            }
            .down-load-url {
              margin-top: -20px;
              height: 80px;
              width: 80px;
            }
            .down-load-item-text {
              display: inline-block;
              height: 20px;
              font-size: 14px;
              color: #354052;
              .icon-title {
                width: 19px;
                height: 21px;
                vertical-align: middle;
                margin-right: 10px;
              }
              &.V2 {
                margin-top: 17px;
              }
            }
            .down-load-img {
              width: 59px;
              height: 59px;
            }
          }
        }
      }
      .select-fiat {
        margin-right: 20px;
      }
      &.el-menu--horizontal {
        border-bottom: none;
      }

      .sub-item-child {
        color: red;
        #sub-item-asset & {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        &.el-submenu {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        .el-submenu__title {
          display: inline-block;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;
          .sub-item-child-jump:hover {
            color: #000;
          }
          a:hover {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 14px;
            display: inline-block;
          }
        }
      }
      .el-submenu {
        &:hover {
          a {
            color: #ffffff;
          }
          i {
            color: #ffffff;
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          line-height: 14px;
          &:hover {
            opacity: 1;
          }
        }
        .el-menu--horizontal {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            //  padding: 10px 0px;
            // width: 140px;
            background-color: #fff;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              // padding-left: 10px;
              // width: 140px;
              &:hover {
                background-color: #f3f7fc;
              }
              &.hide-icon {
                i {
                  visibility: hidden;
                }
              }
              i {
                margin-right: 10px;
                position: relative;
                top: -1px;
                color: inherit;
              }

              &.is-active {
                background-color: #fff !important;
                // span {
                //   color: #3a7dff !important;
                // }
              }
            }
          }
        }
        //修改Lang宽度
        .el-menu--horizontal.sub-item-wrap.select-lang {
          top: 59px !important;
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            width: 130px !important;
            min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        //修改货币宽度
        .el-menu--horizontal.sub-item-wrap.select-fiat-currency {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            // width: 130px !important;
            // min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              &:hover {
                background-color: #f3f7fc;
              }
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      .no-arrow {
        .el-submenu__icon-arrow {
          display: none;
        }
      }
      // .el-submenu.is-opend .el-submenu__title {
      //   background-color: #fff !important;
      // }
      .el-submenu__title {
        border-bottom: 0;
        padding: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 60px;
          // margin-left: 20px;
          box-sizing: border-box;
          &.none-active {
            // width: 40px;
            // border-bottom: 3px solid #32c8a4;
            color: #fff;
            background-color: #12161c;
            &:after {
              content: "";
              height: 3px;
              width: 50px;
              background-color: #32c8a4;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &:hover {
            color: #fff;
            background-color: #12161c;
          }
        }
      }
      .el-submenu__title:hover {
        background-color: #12161c;
        text-align: left;
        color: #ffffff;
        a {
          text-decoration: none;
        }
      }

      .nav-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;
        &.login {
          // margin: 0 10px;
          a {
            color: #ffffff;
            opacity: 0.6;
            &.none-active {
              color: #fff;
              opacity: 1;
              background-color: #12161c;
            }
            &:hover {
              color: #fff;
              opacity: 1;
              // font-weight: 500;
            }
          }
        }
        .driver {
          position: absolute;
          right: -40px;
          top: 0px;
          width: 1px;
          height: 30px;
          margin: 0 15px 0 22px;
          background-color: rgb(255, 255, 255, 0.5);
        }

        &.register {
          position: relative;
          margin-right: 25px;
          background-color: #32c8a4;
          height: 30px;
          margin-top: 15px;
          line-height: 30px;
          border-radius: 2px !important;
          border-right: 1px;
          border-right: 1px soild #acb9db;
          .none-active {
            border-radius: 2px;
          }

          &:hover {
            background: rgba(21, 190, 180, 0.8);
            color: #32c8a4;
          }
          a {
            line-height: 30px;
            color: white;
            border-radius: 4px;
            &.none-active {
              color: #fff;
              background-color: #32c8a4;
            }
          }
        }
        &:hover {
          background-color: #12161c;
          color: #32c8a4;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 57px;
          padding: 0 20px;
          box-sizing: border-box;
          text-decoration: none;
          color: #acb9db;
          &.none-active {
            color: #fff;
            background-color: rgba(40, 41, 45, 1);
          }
          &:hover {
          }
        }
      }
    }

    .lang {
      img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 10px;
        filter: brightness(200);
        -webkit-filter: brightness(150%);
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    background: rgba(216, 216, 216, 1);
    opacity: 0.18;
    position: relative;
    top: 20px;
  }
}
.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
}
.select-fiat-currency {
  top: 59px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}
.add-new-class{
  display: flex;
  align-items: center;
}
</style>
