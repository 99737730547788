<template>
  <div class="maskExAboutUs" ref="activeRef">
    <div :class="['bannerImg', { activeRefClass: AboutContent }]">
      <div class="About-Us">{{ translateTitle("About Us") }}</div>
      <div class="About-content">
        <p class="content">
          {{
            translateTitle(
              "Built to be one of the most secure and private Crypto exchanges globally with multiple layers of safety, MaskEX adopted the slogan 'Your Wallet, Your Way' to reflect its mission to play an essential role in a free economic system by providing the masses with financial anonymity and autonomy"
            )
          }}

        </p>
        <div v-if="isZh">
            Mask Virtual Assets Exchange LLC，在经济和旅游部 (DET) 下注册，注册号为 1001596。
            <br/>
            我们已经在虚拟资产监管局 (VARA) 的监管框架下获得初步批准
          </div>
          <div v-else>
            Mask Virtual Assets Exchange LLC, registered under Department of Economic and Tourism (DET) with registration number 1001596.<br/> We have obtained the Initial approval under the regulatory framework of the Virtual Assets Regulatory Authority (VARA).
          </div>
      </div>
      <!-- <ul>
        <li>
          <p>7 M+</p>
          <span class="Global-users">
            {{ translateTitle("Global users") }}
          </span>
        </li>
        <li>
          <p>3.08 B+</p>
          <span class="Global-users"
            >{{ translateTitle("24H Value(USD)") }}
          </span>
        </li>
        <li>
          <p>1.19B+</p>
          <span class="Global-users">
            {{ translateTitle("Total Trades") }}
          </span>
        </li>
      </ul> -->
    </div>
    <div class="textNote">
      <div class="left">
        <div class="Rectangle-664"></div>
        <div class="securityCont">
          {{
            translateTitle(
              "MaskEX is built on values highly concentrated on users' privacy and security"
            )
          }}
        </div>
        <div class="text-style-1">
          {{
            translateTitle(
              "Founded in 2021, MaskEX is the owner of MSB financial license in Canada and offers a licensed legal trading platform."
            )
          }}
        </div>
        <div class="text-style-2">
          {{
            translateTitle(
              "The Most Secure digital wallet, with an address obfuscation strategy,and unlimited mass addresses.Compliance With Privacy: We abide by the privacy of the blockchain, do not retain any user information, and ensure the security of your assets."
            )
          }}
        </div>
        <div
          class="Start-here"
          @click="
            $router.push({
              path: routeSplicing(language, 'company/careers'),
            })
          "
        >
          <span class="icon iconfont">&#xe787;</span
          >{{ translateTitle("Start here") }}
        </div>
      </div>
      <div class="right">
        <img src="./../../../../src/assets/img/maskEx/mask-group.png" />
      </div>
    </div>
    <div class="testScroll">
      <div class="bannerTxt">
        <div
          :style="{
            height: textContList.length * 436 + 'px',
            marginTop: -indexNum * 436 + 'px',
            transition: '0.5s',
          }"
        >
          <div
            class="textCont"
            v-for="item in textContList"
            :key="'0' + item.title"
          >
            <div class="title">{{ translateTitle(item.title) }}</div>
            <div class="txtCont">{{ translateTitle(item.txtCont) }}</div>
          </div>
          <div class="banner-prev" @click="changeTxt(0)">
            <span class="icon iconfont">&#xe60d;</span>
            <el-progress
              type="circle"
              :stroke-width="3"
              :width="50"
              :key="new Date().valueOf() + scheduleNum + 'messi'"
              :show-text="false"
              :percentage="scheduleNum"
              :color="['#5BD2CB', '#00B4A9']"
            ></el-progress>
          </div>
          <div class="banner-next" @click="changeTxt(0)">
            <span class="icon iconfont">&#xe60b;</span>
          </div>
          <div class="banner slide">
            <div class="banner-points">
              <span
                :class="['banner-point', indexNum == i ? 'active' : '']"
                v-for="(item1, i) in textContList"
                :key="'0' + i"
                @click="changePoints(i)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="companyProfile">
      <div class="companyProfileCount">
        <p class="content" v-if="isZh">
          我们开发了 MaskEX 桌面版和应用版本，主要考虑了新手交易者的需求，同时也满足了有经验的交易者的需求。MaskEX 提供智能、直观且创新的在线现货交易，具有用户友好性和卓越性能。
          <!-- {{
            translateTitle(
              "We developed MaskEX desktop and app versions primarily considering the profile of a novice trader, nevertheless addressing the needs of experienced traders. MaskEX offers smart, intuitive and innovative online trading (Spot trading, Futures trading (Copy, USDT-M, COIN-M, Option), OTC, Margin trading and much more) with user-friendliness and great performance."
            )
          }} -->
        </p>
        <p class="content" v-else>
          We developed MaskEX desktop and app versions primarily considering the profile of a novice trader, nevertheless addressing the needs of experienced traders. MaskEX offers smart, intuitive and innovative online Spot trading, with user-friendliness and great performance
        </p>
      </div>

      <div class="licompanyProfile test-class">
        <ul>
          <li>
            <img src="./../../../../src/assets/img/maskEx/img1.webp" />
            <p>{{ translateTitle("Technology") }}</p>
            <div>
              {{
                translateTitle(
                  "We are leveraging cutting-edge technologies to create useful services to improve accessibility to the blockchain revolution"
                )
              }}
            </div>
          </li>
          <li>
            <img src="./../../../../src/assets/img/maskEx/img2.webp" />
            <p>{{ translateTitle("Security & Stability") }}</p>
            <div>
              {{
                translateTitle(
                  "We ensure the highest levels of security at all times with continuous vigilance"
                )
              }}
            </div>
          </li>
          <li>
            <img src="./../../../../src/assets/img/maskEx/img3.webp" />
            <p>{{ translateTitle("Global Operations") }}</p>
            <div>
              {{
                translateTitle(
                  "Global operational team with rich experiences in blockchain and Fintech industry, covering 15 language areas globally"
                )
              }}
            </div>
          </li>

          <li>
            <img src="./../../../../src/assets/img/maskEx/img4.webp" />
            <p>{{ translateTitle("User-First") }}</p>
            <div>
              {{
                translateTitle(
                  "Our products are inspired by our users and we continuously engage and listen to our community across the region"
                )
              }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="ourTeam">
      <div class="contBlass">
        <div class="left foot_banner">
          <div class="bigImg">
            <!-- style="max-height: 340px" -->
            <!-- fit="cover" -->
            <!-- style="width: 627px; height: 340px" -->
            <el-image :src="url" :preview-src-list="srcList"> </el-image>
            <!-- <img src="./../../../../src/assets/img/maskEx/ourTeam/1.png" /> -->
          </div>
          <ul>
            <div class="width_hidden">
              <div
                class="same_img_count"
                :style="{
                  width: srcList.length * 2 * 100 + 'px',
                  transform: 'translateX(' + transformTralteX + 'px)',
                  transition: '0.1s',
                }"
                @mouseenter="stopPlayImg()"
                @mouseleave="autoPlayImg()"
              >
                <li
                  v-for="(item, i) in srcList"
                  :key="i + 'some'"
                  :class="ourTeamIndex == i ? 'active' : null"
                  @click="changOurTeam(i, item)"
                >
                  <div><el-image :src="item"> </el-image></div>
                </li>
                <li
                  v-for="(item, i) in srcList"
                  :key="i + 'copy'"
                  :class="ourTeamIndex == i ? 'active' : null"
                  @click="changOurTeam(i, item)"
                >
                  <div><el-image :src="item"> </el-image></div>
                </li>
              </div>
            </div>
            <div
              class="rightHeard"
              @click="ourTeamAdd"
              @mouseenter="stopPlayImg()"
              @mouseleave="autoPlayImg()"
            >
              <span class="icon iconfont">&#xe787;</span>
            </div>
            <div
              class="leftHeard"
              @click="ourTeamAdd('-')"
              @mouseenter="stopPlayImg()"
              @mouseleave="autoPlayImg()"
            >
              <span class="icon iconfont">&#xe787;</span>
            </div>
          </ul>
        </div>
        <div class="right">
          <div class="title">{{ translateTitle("Our Team") }}</div>
          <div class="content">
            {{
              translateTitle(
                "MaskEX is a large multinational team, located in several different"
              )
            }}
            {{
              translateTitle(
                "geographic regions. Our team members speak multiple languages and"
              )
            }}
            {{
              translateTitle(
                "serve clients around the world. We are experts in IT and software"
              )
            }}
            {{
              translateTitle(
                "development for the FinTech industry, each division made up of"
              )
            }}
            {{ translateTitle("leading professionals.") }}
            <p
              @click="
                $router.push({
                  path: routeSplicing(language, 'company/careers'),
                })
              "
            >
              <span class="icon iconfont">&#xe787;</span>
              {{ translateTitle("Join us") }}
            </p>
          </div>
        </div>
      </div>
      <div class="l-dis_part" v-if="isZh">
        <div class="dis_info">
          <div class='pit' style='margin-right: 20px;'>
            <img src="../../../assets/img/ceo.png" alt="">
          </div>
          <div class="info">
            <div class="tit">首席执行官 - CHEN SHUAILUN</div>
            <div>CHEN SHUAILUN 是一位拥有超过10年企业服务领域经验的成功首席执行官。他在领导和管理方面采取目标导向和跨职能的方法，结合了强大的分析、组织、沟通和技术技能，以及积极和有目的的工作态度。在 MaskEX，CHEN 正利用他在公司战略、运营和绩效管理方面的丰富经验，推动增长并确保合规。他在管理运营预算、招聘和利益相关者关系方面的专业知识，以及他在利用技术提升业务价值方面的能力，对于引导 MaskEX 实现其战略目标至关重要。CHEN 在制定公司政策、管理财务组合和确保客户满意度方面的领导力，与 MaskEX 在虚拟资产领域追求卓越和创新的承诺完全契合。他对持续改进、客户参与和运营效率的奉献，支持着 MaskEX 保持其行业领导者的地位。</div>
          </div>
        </div>
        <div class="dis_info">
          <div class="info">
            <div class="tit">首席财务官 - Amr Abdelwahab</div>
            <div>Amr Abdelwahab是一位经验丰富的财务控制师和认证内部审计师，在阿联酋政府和航空部门执行高影响力财务和运营审计方面拥有杰出的记录。在 MaskEX，Amr 正利用他在基于风险的审计计划以及大型和资本项目审计方面的专业知识，提升我们的财务完整性和运营效率。他对风险和控制的深刻理解，以及实现成本节约和风险减轻的丰富经验，使他能够支持 MaskEX 在虚拟资产的复杂监管环境中导航。作为一名值得信赖的顾问，Amr 正在提供战略见解，以推动成功的审计结果并确保符合行业标准。他对 IFRS、IPSAS、VAT、CT、BCM 和风险管理的全面了解，对于加强 MaskEX 的财务治理和风险管理框架至关重要。Amr 的领导才能和战略方法在于推动 MaskEX 内部控制的持续改进和稳健发展。</div>
          </div>
          <div class='pit' style='margin-left: 20px;'>
            <img src="../../../assets/img/cfo.png" alt="">
          </div>
        </div>
      </div>
      <div class="l-dis_part" v-else>
        <div class="dis_info">
          <div class='pit' style='margin-right: 20px;'>
            <img src="../../../assets/img/ceo.png" alt="">
          </div>
          <div class="info">
            <div class="tit">CEO - CHEN SHUAILUN</div>
            <div>CHEN SHUAILUN is an accomplished CEO with over 10 years of experience in the corporate services field. He brings a goal-oriented and cross-functional approach to leadership and management, combining strong analytical, organizational, interpersonal, and technical skills with a proactive and purposeful approach. At MaskEX, CHEN  is leveraging his extensive experience overseeing company strategy, operations, and performance to drive growth and ensure regulatory compliance. His expertise in managing operational budgets, recruitment, and stakeholder relationships, alongside his proficiency in utilizing technology to enhance business value, is instrumental in steering MaskEX towards achieving its strategic objectives. CHEN’s leadership in developing company policies, managing financial portfolios, and ensuring client satisfaction aligns perfectly with MaskEX's commitment to excellence and innovation in the virtual asset space. His dedication to continuous improvement, client engagement, and operational efficiency is supporting MaskEX in maintaining its position as a leader in the industry.</div>
          </div>
        </div>
        <div class="dis_info">
          <div class="info">
            <div class="tit">CFO - Amr Abdelwahab</div>
            <div>Amr Abdelwahab is an experienced Financial Controller and Certified Internal Auditor with a distinguished track record in delivering high-impact financial and operational audits within the UAE Government and Aviation sectors. At MaskEX, Amr is leveraging his expertise in risk-based audit plans and high-profile mega and capital projects audits to enhance our financial integrity and operational efficiency. His deep understanding of risks and controls, combined with a history of achieving cost savings and risk mitigation, positions him to support MaskEX in navigating the complex regulatory landscape of virtual assets. As a trusted advisor, Amr is providing strategic insights to drive successful audit outcomes and ensure compliance with industry standards. His comprehensive knowledge of IFRS, IPSAS, VAT, CT, BCM, and Risk Management is instrumental in strengthening MaskEX's financial governance and risk management framework. Amr's leadership skills and strategic approach is a key in fostering a culture of continuous improvement and robust internal controls at MaskEX.</div>
          </div>
          <div class='pit' style='margin-left: 20px;'>
            <img src="../../../assets/img/cfo.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  mixins: [commonMixin],
  data() {
    return {
      isZh:localStorage.getItem('language') === 'zh_CN' || localStorage.getItem('language') === 'zh_HK',
      url: require("./../../../../src/assets/img/maskEx/ourTeam/1.png"),
      srcList: [],
      textContList: [
        {
          title: "Our Vision",
          txtCont:
            "We envision a world where individuals have control over their financial anonymity and independence.",
        },
        {
          title: "Our Mission",
          txtCont:
            "We seek to play an essential role in giving the masses a free economic system.",
        },
        {
          title: "Our Values",
          txtCont:
            "Technological excellence, privacy and security, leadership and courage to change, be socially responsible - values underlying MaskEX.",
        },
      ],
      ourTeamIndex: 0,
      indexNum: 0,
      timer: null, // 计时器
      timerSchedule: null, // 进度条计时器
      timeFootBanner: null, // 页脚图片定时器
      scheduleNum: 0,
      AboutContent: false,
      currentScroll: 0,
      transformTralteX: 0,
    };
  },
  watch: {
    ourTeamIndex(value) {
      // 无缝滚动靠这个++
      let all = this.srcList.length * 2 * 100;
      // let half = all >> 1;  如果效果不满意就计算过一半  但是这个费劲 就没用这个值
      let page = 0; // 分页是第几页
      if (value / 5 >= 1) {
        // 因为从0开始5就是 第六个图
        page = Math.floor(value / 5);
      } else {
        page = 0;
      }
      this.transformTralteX = -page * 500;
      if (this.ourTeamIndex >= this.srcList.length) {
        //如果大于图片的张数 从新开始
        this.ourTeamIndex = 0;
      }
      this.url = this.srcList[value];
    },
  },
  created () {
    // 1.3.4.5.6.8.9.
    const idxLs = [1,3,4,5,6,8,9]
    // for (let i = 1; i < 14; i++) {
      // this.srcList.push(
      //   require("./../../../../src/assets/img/maskEx/ourTeamEx/" + i + ".png")
      // );
    // }
    idxLs.forEach(idx => {
      this.srcList.push(
        require("./../../../../src/assets/img/maskEx/ourTeamEx/" + idx + ".png")
      );
    })
  },
  mounted() {
    this.autoStart(); //环形进度条定时器
    this.autoPlayImg(); //图片轮播定时器
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.AboutContent = true;
  },
  methods: {
    routeSplicing,
    changOurTeam(i, src) {
      this.ourTeamIndex = i;
      this.url = src;
    },
    ourTeamAdd(value) {
      // 无缝滚动的箭头
      if (value == "-") {
        if (this.ourTeamIndex == 0) {
          return (this.ourTeamIndex = this.srcList.length - 1);
        }
        this.ourTeamIndex--;
      } else {
        this.ourTeamIndex++;
        if (this.ourTeamIndex >= this.srcList.length) {
          this.ourTeamIndex = 0;
        }
      }
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset;
    },
    changePoints(i) {
      this.scheduleNum = 0;
      this.indexNum = i;
    },
    changeTxt(val) {
      this.scheduleNum = 0;
      val
        ? this.indexNum == 0
          ? (this.indexNum = this.textContList.length - 1)
          : this.indexNum--
        : this.indexNum == this.textContList.length - 1
        ? (this.indexNum = 0)
        : this.indexNum++;
    },
    autoStart() {
      if (this.timerSchedule) {
        return;
      }
      this.timerSchedule = setInterval(() => {
        this.scheduleNum++;
        if (this.scheduleNum == 101) {
          this.scheduleNum = 0;
          this.indexNum++;
          if (this.textContList.length == this.indexNum) {
            this.indexNum = 0;
          }
        }
      }, 50);
    },
    autoPlayImg() {
      if (this.timeFootBanner) {
        return;
      }
      this.timeFootBanner = setInterval(() => {
        this.ourTeamIndex++;
        if (this.ourTeamIndex >= this.srcList.length) {
          this.ourTeamIndex = 0;
        }
      }, 5000);
    },
    stopPlayImg() {
      if (this.timeFootBanner) {
        clearInterval(this.timeFootBanner);
        this.timeFootBanner = null;
      }
    },
  },
  destroyed() {
    // 页面销毁清楚定时器 舰艇
    window.removeEventListener("scroll", this.handleScrollbox, true);
    if (this.timerSchedule) {
      clearInterval(this.timerSchedule);
    }
    this.stopPlayImg();
  },
};
</script>
<style lang="scss">
.ar .maskExAboutUs {
  direction: rtl !important;
  .licompanyProfile {
    ul {
      li {
        div {
          &::before {
            transform: translateX(15px) !important;
          }
        }
      }
    }
  }
}
.maskExAboutUs {
  .bannerImg {
    &.activeRefClass {
      .About-Us {
        transform: translateX(0) !important;
        opacity: 1 !important;
        transition: all 1s ease;
      }
      .About-content {
        transform: rotate(0) !important;
        opacity: 1 !important;
        transition: all 1s ease;
      }
    }
  }
  width: 100%;
  margin: 0px;
  padding: 0px;
  .itemContent {
    width: 780px;
  }
  .bannerImg {
    .About-Us {
      padding-top: 200px;
      font-size: 70px;
      font-weight: bold;
      padding-bottom: 16px;
      opacity: 0; //滚动前设置透明隐藏
      transform: translateX(10%);
    }
    .About-content {
      opacity: 0; //滚动前设置透明隐藏
      transform: rotate(30deg);
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      .content {
        width: 713px;
        height: 88px;
        margin: auto;
      }
    }
    ul {
      margin-top: 148px !important;
      width: 780px;
      display: flex;
      margin: 0 auto;
      li {
        p {
          font-size: 40px;
        }
        width: 260px;
      }
    }
    height: 640px;
    text-align: center;
    color: #fff;
    background: url("./../../../../src/assets/img/maskEx/bannerImg.png");
  }
  .textNote {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    .left {
      .Rectangle-664 {
        margin-top: 84px;
        width: 60px;
        height: 6px;
        background-color: #15beb4;
        margin-bottom: 10px;
      }
      .securityCont {
        max-width: 605px;

        font-size: 40px;
        font-weight: bold;
        text-align: left;
        color: #15192a;
      }
      .text-style-1 {
        margin-top: 48px;
        max-width: 600px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
      }

      .text-style-2 {
        margin-top: 48px;
        max-width: 600px;
        font-size: 16px;
        color: #666;
      }
      .Start-here {
        margin-top: 40px;
        // width: 92px;
        height: 24px;

        font-size: 20px;
        font-weight: 500;
        // text-align: left;
        color: #15beb4;
        cursor: pointer;
      }
    }
    .right {
      width: 452px;
      height: 609.5px;
      img {
        width: 100%;
      }
    }
  }
  .testScroll {
    width: 100%;
    background-color: #15beb4;
    .bannerTxt {
      max-width: 1200px;
      height: 436px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      transition: 0.5s;
    }
    .textCont {
      text-align: center;
      height: 436px;
      overflow: hidden;
      .title {
        margin-top: 140px;

        font-weight: 700;
        font-size: 52px;
        line-height: 63px;
        color: #fff;
      }
      .txtCont {
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        max-width: 700px;
        margin: 0 auto;
        text-align: center;
        color: #ffffff;
        margin-top: 25px;
      }
    }

    .banner-prev,
    .banner-next {
      width: 47px;
      height: 47px;
      flex-grow: 0;
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50px;
      z-index: 100;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 30px;
        position: absolute;
        z-index: 99;
        display: inline-block;
      }
    }
    .banner-prev {
      margin-top: -100px;
      span {
        top: 10px;
        left: 10px;
      }
    }
    .banner-next {
      margin-bottom: -100px;
      border: solid 3px #fff;
      span {
        left: 6px;
        top: 6px;
      }
    }
    .banner-points {
      position: absolute;
      display: inline-block;
      width: 20px;
      left: auto;
      right: 4px;
      top: 50%;
      margin-top: -30px;
      text-align: right;
      .banner-point {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        margin: 0 4px;
        cursor: pointer;
      }
      .banner-point:hover {
        background: #00b4a9 !important;
      }

      .active {
        background: #fff !important;
      }
    }
    .progressBar >>> .el-progress-bar__outer {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }
    /* 进度条的背景色 */
    .progressBar >>> .el-progress-bar__inner {
      background-color: #00b4a9 !important;
    }
  }
  .companyProfile {
    height: 616px;
    clear: both;

    background: url("./../../../../src/assets/img/maskEx/groupMap.png");
    .companyProfileCount {
      font-size: 16px;
      text-align: center;
      padding: 105px 0 0 0;
      color: #fff;
      .content {
        width: 821px;
        height: 84px;
        margin: auto;
      }
    }
    .licompanyProfile {
      ul {
        width: 1100px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        margin: 80px auto 0 auto;
        li {
          position: relative;
          max-width: 350px;
          min-height: 300px;
          margin-right: 50px;
          img {
            width: 60px;
            height: 60px;
            display: inline-block;
            margin: 0;
            padding: 0;
          }
          p {
            padding: 25px 0 35px 0;
            min-width: 150px;
            min-height: 80px;
          }
          div::before {
            content: " ";
            width: 7px;
            height: 7px;
            background-color: #15beb4;
            position: absolute;
            border-radius: 10px;
            top: 155px;
            transform: translateX(-15px);
          }
          div {
            font-size: 16px;
            font-weight: normal;
            width: 220px !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
  .ourTeam {
    background: #0f141c;
    // height: 740px;
    clear: both;
    padding-bottom: 60px;
    .contBlass {
      width: 1200px;
      margin: 0 auto;
      height: 569px;
      display: flex;
      padding-top: 88px;
      .left {
        width: 627px;
        height: 569px;
        &.foot_banner {
          .bigImg {
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-bottom: 30px;
            text-align: center;
            img {
              height: 400px;
              margin: 0 auto;
              object-fit: cover;
            }
          }
          ul {
            .width_hidden {
              width: 100%;
              overflow: hidden;
            }
            width: 100%;
            position: relative;
            li {
              &.active {
                div {
                  opacity: 1;
                }
              }
              &:hover {
                div {
                  opacity: 1 !important;
                }
              }
              min-width: 90px;
              max-width: 90px;
              overflow: hidden;
              height: 56px;
              margin-right: 10px;
              flex: 90px;
              text-align: center;
              background: #000;
              div {
                opacity: 0.7;
              }
              img {
                height: 56px;
                margin: 0 auto;
                min-width: 90px;
                object-fit: cover;
              }
            }
            .rightHeard {
              color: #fff;
              position: absolute;
              top: 50%;
              right: -20px;
              margin-top: -8px;
              cursor: pointer;
            }
            .leftHeard {
              color: #fff;
              position: absolute;
              top: 50%;
              left: -20px;
              margin-top: -8px;
              transform: rotate(180deg);
              cursor: pointer;
            }
            .same_img_count {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              overflow: hidden;
              justify-content: flex-start;
              position: relative;
              overflow-x: auto;
            }
          }
        }
      }
      .right {
        width: 373px;
        position: relative;
        height: 300px;
        .title {
          // position: absolute;
          padding: 22px 40px;
          flex-grow: 0;
          font-size: 52px;
          font-weight: bold;
          text-align: left;
          color: #fff;
        }
        .content {
          width: 592px;
          height: 270px;
          flex-grow: 0;
          padding: 44px 55px 44px 63px;
          margin-left: -10px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          background-color: #15beb4;
          p {
            margin-top: 40px;
            cursor: pointer;
          }
        }
      }
    }
    .l-dis_part{
      width: 1200px;
      margin: 0 auto;
      margin-top: 50px;
       color: #fff;
      .dis_info{
        display: flex;
        margin-bottom: 60px;
        align-items: center;
        .pit{
          width: 200px;
          height: 200px;
          flex-shrink: 0;

          img{
            width: 100%;
            height: 100%;
          }
        }
        .info{
          .tit{
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .address_contact{
    width: 1200px;
    margin: 0 auto;;
    margin-top: 60px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
}
</style>
