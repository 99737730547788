<template>
  <div class="quickly-buy-coins-content-page scrollbar-default">
    <div class="quickly-rechange-header">
      <div class="quickly-rechange-header-content">
        <div class="left-pages">
          <ul class="pages-routes">
            <li :class="active == 1 ? 'active' : ''" @click="changeActive(1)" v-if="showQuickTradingTab">
              <a>{{ translateTitle("三方买币") }}</a>
            </li>

            <li :class="active == 2 ? 'active' : ''" @click="changeActive(2)">
              <a v-if="language != 'en'">{{ translateTitle("充值") }}</a>
              <a v-else>{{ "Deposit" }}</a>
            </li>
          </ul>
        </div>
        <!-- <div class="right-pages">
          <ul class="pages-routes">
            <li>
              <i class="iconfont iconarrow-right"></i
              ><span>{{ translateTitle("买币教程") }}</span>
            </li>

            <li>
              <i class="iconfont iconbaipishu"></i
              ><span>{{ translateTitle("购买记录") }}</span>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="quickly-rechange-center" v-if="active == 1">
      <div class="quickly-rechange-center-content">
        <p class="purchasing-price-text">
          <span class="step">1</span>{{ translateTitle("选择购买金额") }}
        </p>
        <div class="purchasing-price-do first">
          <div class="coin form-coin">
            <p class="coin-suffix">{{ translateTitle("我想花费") }}</p>
            <div class="coin-input">
              <el-input
                class="coin-el-input"
                v-model="spendValue"
                :placeholder="translateTitle('请输入金额')"
              ></el-input>
              <div class="splite"></div>

              <el-select
                :popper-append-to-body="false"
                filterable
                placeholder=""
                :style="{
                  background:
                    'url(' +
                    (currencyCurr && currencyCurr.icon) +
                    ') no-repeat 9.5px center #fff',
                }"
                style="width: 122px; height: 54px"
                :value="currencyCurr && currencyCurr.logic_name.toUpperCase()"
                @change="selectChange"
              >
                <el-option
                  :value="val"
                  v-for="(val, key) in currencyList"
                  :key="key"
                  :label="val.logic_name.toUpperCase()"
                  :class="{
                    selected: currencyCurr.currency_id == val.currency_id,
                  }"
                >
                  <img
                    :src="val.icon"
                    style="width: 24px; height: 24px; vertical-align: middle"
                  />
                  <span style="margin-left: 10px;color:#333;font-weight:400;">{{
                    val.logic_name.toUpperCase()
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="convert">
            <i class="iconfont iconright iconsize"></i>
          </div>
          <div class="coin to-coin">
            <p class="coin-suffix">{{ translateTitle("购买币种") }}</p>
            <div class="coin-input">
              <el-input
                class="coin-el-input"
                :value="coinCurr && coinCurr.item_name.toUpperCase()"
                disabled
              ></el-input>
              <div class="splite"></div>

              <el-select
                :popper-append-to-body="false"
                filterable
                placeholder=""
                :style="{
                  background:
                    'url(' +
                    (coinCurr && coinCurr.itemDetail.coin_url) +
                    ') no-repeat 9.5px center #fff',
                }"
                style="width: 122px; height: 54px"
                :value="coinCurr && coinCurr.item_name.toUpperCase()"
                @change="selectChangeCoin"
              >
                <el-option
                  :value="val"
                  v-for="(val, key) in coinBuyAvailable"
                  :key="key"
                  :label="val.item_name.toUpperCase()"
                  :class="{
                    selected: coinCurr.item_id == val.item_id,
                  }"
                >
                  <img
                    :src="val.itemDetail.coin_url"
                    style="width: 24px; height: 24px; vertical-align: middle"
                  />
                  <span style="margin-left: 10px;color:#333;font-weight:400;">{{
                    val.item_name.toUpperCase()
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <p class="purchasing-price-text">
          <span class="step">2</span> {{ translateTitle("选择付款方式") }}
        </p>
        <div class="purchasing-price-do second">
          <table>
            <tr v-for="n in rowCount" :key="n">
              <td
                :class="
                  radioCurr == thirdPaymentShowArray[(n - 1) * 2]['name']
                    ? 'active'
                    : ''
                "
                @click="
                  radioCurr =
                    thirdPaymentShowArray[(n - 1) * 2] &&
                    thirdPaymentShowArray[(n - 1) * 2]['name']
                "
              >
                <div class="payment-channel">
                  <div
                    class="left"
                    @click="
                      radioCurr =
                        thirdPaymentShowArray[(n - 1) * 2] &&
                        thirdPaymentShowArray[(n - 1) * 2]['name']
                    "
                  >
                    <img
                      class="channel-radio"
                      :src="
                        radioCurr == thirdPaymentShowArray[(n - 1) * 2]['name']
                          ? choose_a
                          : choose
                      "
                    />
                    <img
                      :src="
                        thirdPaymentShowArray[(n - 1) * 2] &&
                          thirdPaymentShowArray[(n - 1) * 2]['img']
                      "
                      class="channel-logo"
                    />
                    <div class="name-realprice">
                      <span class="name">{{
                        thirdPaymentShowArray[(n - 1) * 2] &&
                          thirdPaymentShowArray[(n - 1) * 2]["name"]
                      }}</span>
                    </div>
                  </div>
                  <div class="right">
                    <img :src="visa" class="channel-sub" />
                    <img :src="mastercard" class="channel-sub" />
                    <div class="channel-sub-more">···</div>
                  </div>
                </div>
              </td>
              <td
                class="lastCol"
                :class="
                  radioCurr ==
                  (thirdPaymentShowArray[(n - 1) * 2 + 1] &&
                    thirdPaymentShowArray[(n - 1) * 2 + 1]['name'])
                    ? 'active'
                    : ''
                "
                @click="
                  (n - 1) * 2 + 2 <= thirdPaymentShowArray.length
                    ? (radioCurr =
                        thirdPaymentShowArray[(n - 1) * 2 + 1] &&
                        thirdPaymentShowArray[(n - 1) * 2 + 1]['name'])
                    : ''
                "
              >
                <div class="payment-channel">
                  <div
                    v-if="(n - 1) * 2 + 2 > thirdPaymentShowArray.length"
                  ></div>
                  <div
                    v-else
                    class="left"
                    @click="
                      radioCurr =
                        thirdPaymentShowArray[(n - 1) * 2 + 1] &&
                        thirdPaymentShowArray[(n - 1) * 2 + 1]['name']
                    "
                  >
                    <img
                      class="channel-radio"
                      :src="
                        radioCurr ==
                        thirdPaymentShowArray[(n - 1) * 2 + 1]['name']
                          ? choose_a
                          : choose
                      "
                    />
                    <img
                      :src="
                        thirdPaymentShowArray[(n - 1) * 2 + 1] &&
                          thirdPaymentShowArray[(n - 1) * 2 + 1]['img']
                      "
                      class="channel-logo"
                    />
                    <div class="name-realprice">
                      <span class="name">{{
                        thirdPaymentShowArray[(n - 1) * 2 + 1] &&
                          thirdPaymentShowArray[(n - 1) * 2 + 1]["name"]
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-if="(n - 1) * 2 + 2 > thirdPaymentShowArray.length"
                  ></div>
                  <div v-else class="right">
                    <img :src="visa" class="channel-sub" />
                    <img :src="mastercard" class="channel-sub" />
                    <div class="channel-sub-more">···</div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <p class="statement">
          {{
            translateTitle("免责声明:  您即将离开") +
              " MaskEX " +
              translateTitle(
                "网站并经由第三方外部链接跳转至新的浏览器窗口。通过此超链接访问的网站并非"
              ) +
              " MaskEX " +
              translateTitle(
                " 的所属网站，页面访问风险均由用户自行承担。我们对外部网站的任何内容、广告、产品、服务或其他资讯概不负责。此处提供的链接也不意味着我们对这些网站上或通过这些网站提供的任何产品或服务进行认可。对于因使用第三方网站上提供的内容或服务而造成的任何损失或损害，我们概不负责。如果您在使用此类网站的服务时遇到任何问题，请直接与该网站联系。"
              )
          }}
        </p>
        <div class="checkbox-out">
          <el-checkbox
            v-model="checked"
            fill="#15bfb5"
            class="statement-checkbox"
            >{{ translateTitle("我已阅读并同意该免责声明") }}</el-checkbox
          >
        </div>
        <div class="confirm" v-if="!checked">{{ translateTitle("购买") }}</div>
        <div class="confirm create-btn" v-else @click="doBuy()">
          {{ translateTitle("购买") }}
        </div>
      </div>
    </div>
    <Recharge v-if="active == 2" />
    <div class="quickly-rechange-footer" v-if="active == 1">
      <div class="quickly-rechange-footer-content">
        <p class="questions">{{ translateTitle("常见问题") }}</p>
        <p class="question">
          <span>1、</span>{{ translateTitle("快捷购买页常见问题1") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页常见答案1") }}
        </p>
        <p class="question">
          <span>2、</span>{{ translateTitle("快捷购买页常见问题2") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页常见答案2") }}
        </p>
        <p class="question">
          <span>3、</span>{{ translateTitle("快捷购买页常见问题3") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页常见答案3") }}
        </p>
        <p class="question">
          <span>4、</span>{{ translateTitle("快捷购买页常见问题4") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页常见答案4") }}
        </p>
        <p class="question">
          <span>5、</span>{{ translateTitle("快捷购买页常见问题5") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页常见答案5") }}
        </p>
        <a
          href="https://maskex.zendesk.com/hc/en-us"
          target="_blank"
          class="questions-more"
          >{{ translateTitle("更多问题") }}</a
        >
      </div>
    </div>
    <div class="quickly-rechange-footer" v-if="active == 2">
      <div class="quickly-rechange-footer-content">
        <p class="questions">{{ translateTitle("常见问题") }}</p>
        <p class="question">
          <span>1、</span>{{ translateTitle("快捷购买页充值常见问题1") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页充值常见答案1") }}
        </p>
        <p class="question">
          <span>2、</span>{{ translateTitle("快捷购买页充值常见问题2") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页充值常见答案2") }}
        </p>
        <p class="question">
          <span>3、</span>{{ translateTitle("快捷购买页充值常见问题3") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页充值常见答案3") }}
        </p>
        <p class="question">
          <span>4、</span>{{ translateTitle("快捷购买页充值常见问题4") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页充值常见答案4") }}
        </p>
        <p class="question">
          <span>5、</span>{{ translateTitle("快捷购买页充值常见问题5") }}
        </p>
        <p class="answer">
          {{ translateTitle("快捷购买页充值常见答案5") }}
        </p>

        <a
          href="https://maskex.zendesk.com/hc/en-us"
          target="_blank"
          class="questions-more"
          >{{ translateTitle("更多问题") }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetStaticTableInfo } from "@/model/settings";
import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import { mapGetters } from "vuex";

import visa from "@/assets/img/quickly-coins/visa.png";
import mastercard from "@/assets/img/quickly-coins/mastercard.png";
import moonpay from "@/assets/img/quickly-coins/Moonpay.png";
import mercuryo from "@/assets/img/quickly-coins/mercuryo_n.png";
import paxful from "@/assets/img/quickly-coins/Paxful.png";
import transak from "@/assets/img/quickly-coins/Transak.png";
import routelay from "@/assets/img/quickly-coins/Routelay.png";
import itez from "@/assets/img/quickly-coins/itez.png";
import alchemypay from "@/assets/img/quickly-coins/AlchemyPay.png";
import wyre from "@/assets/img/quickly-coins/wyre.png";

import ipay from "@/assets/img/quickly-coins/ipay.png";
import scpa from "@/assets/img/quickly-coins/scpa.png";
import choose_a from "@/assets/img/quickly-coins/m-choose-a.png";
import choose from "@/assets/img/quickly-coins/m-choose.png";
import * as Account from "@/model/user/account";
import commonMixin from "@/components/common/commonMixin.vue";
import Recharge from "@/pages/quicklycoins/recharge.vue";
import CryptoJS from "crypto-js/crypto-js";
import {
  apiGetFinanceAddressList,
  apiWyreUrl,
  apiFetchOtcUrl,
} from "@/model/assets/index";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: { Recharge },
  data() {
    return {
      visa,
      mastercard,
      ipay,
      scpa,
      moonpay,
      mercuryo,
      paxful,
      transak,
      routelay,
      itez,
      alchemypay,
      wyre,
      active:  2,
      checked: false,
      spendValue: "",
      currencyList: [],
      thirdPaymentMap: [],
      otcThirdPaymentMap: [],
      coinData: {},
      coinBuyAvailable: [],
      thirdPaymentArray: [],
      thirdPaymentShowArray: [],
      currencyCurr: "",
      coinCurr: "",
      radioCurr: "",
      addressCurr: "",
      rowCount: 0,
      // showQuickTradingTab:localStorage.getItem('isALQ') === 'no',
      showQuickTradingTab:false,
      choose_a,
      choose,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
  },
  watch: {
    currencyCurr(val) {
      let tempArray = [];
      if (val.logic_name.toUpperCase() != "USD") {
        this.thirdPaymentArray.forEach(item => {
          if (item.name.toLowerCase() != "wyre") {
            tempArray.push(item);
          }
        });
      } else {
        tempArray = this.thirdPaymentArray;
      }
      // tempArray = this.thirdPaymentArray;
      this.rowCount = Math.ceil(tempArray.length / 2);

      let index = null;
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].name.toLowerCase() == "itez") {
          index = i;
          break;
        }
      }
      if (index !== null) {
        tempArray.unshift(...tempArray.splice(index, 1));
      }
      this.thirdPaymentShowArray = tempArray;
    },
  },
  async created () {


    this.goLogin();
    await this.getItemList();
    await this.getStaticCurrencyMap();
    await this.getStaticOtcThirdPaymentMap();
    await this.getStaticThirdPaymentMap();
    this.currencyCurr = this.currencyList[0];
    this.otcThirdPaymentMap.forEach(ele => {
      this.coinBuyAvailable.push(
        Object.assign({}, this.coinData[ele.item_id], ele)
      );
    });
    this.coinCurr = this.coinBuyAvailable[0];
    this.selectChangeCoin(this.coinCurr);
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex",
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      }
    },
    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2",
      };
      const { status, data } = await getCoinList(req);
      if (status == 200) {
        this.coinData = data;
      }
    },
    //7-static_currency-法币表
    async getStaticCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.currencyList = Object.values(JSON.parse(JSON.stringify(data)));
      }
    },
    async getStaticOtcThirdPaymentMap() {
      // const { status, data } = await apiGetStaticTableInfo(64);
      const { status, data } = await apiGetStaticTableInfo(69);
      if (status == 200) {
        this.otcThirdPaymentMap = Object.values(
          JSON.parse(JSON.stringify(data))
        );
      }
    },
    async getStaticThirdPaymentMap() {
      // const { status, data } = await apiGetStaticTableInfo(65);
      const { status, data } = await apiGetStaticTableInfo(68);
      if (status == 200) {
        this.thirdPaymentMap = Object.values(JSON.parse(JSON.stringify(data)));
        this.thirdPaymentMap.forEach(item => {
          item["img"] = this.$data[item.name.toLowerCase()];
        });
      }
    },
    changeActive(num) {
      this.active = num;
    },
    selectChange(val) {
      this.currencyCurr = val;
    },
    selectChangeCoin(val) {
      this.coinCurr = val;

      this.getAddress();
      let tempPaymentArray = [];
      this.coinCurr.payment.split(",").forEach(ele => {
        this.thirdPaymentMap.forEach(item => {
          if (ele == item.name) {
            tempPaymentArray.push(item);
          }
        });
      });

      // tempPaymentArray.push({ name: "Wyre", img: this.wyre });

      this.thirdPaymentArray = tempPaymentArray;
      // this.radioCurr = this.thirdPaymentArray[0].name;
      this.rowCount = Math.ceil(tempPaymentArray.length / 2);
      this.thirdPaymentShowArray = tempPaymentArray;
      let index = null;
      for (let i = 0; i < this.thirdPaymentShowArray.length; i++) {
        if (this.thirdPaymentShowArray[i].name.toLowerCase() == "itez") {
          index = i;
          break;
        }
      }
      if (index !== null) {
        this.thirdPaymentShowArray.unshift(
          ...this.thirdPaymentShowArray.splice(index, 1)
        );
      }
      this.radioCurr = this.thirdPaymentShowArray[0].name;
    },
    async getAddress() {
      let params = {
        item_id: this.coinCurr.item_id,
        chain_tag_list: this.coinCurr.chain_name.toUpperCase(),
      };
      if (!this.userInfo) return;
      const { status, msg, data } = await apiGetFinanceAddressList(params);
      if (status == 200) {
        this.addressCurr = data[0]["address"];
      }
    },
    async doBuy() {
      if (!this.spendValue) {
        this.$message({
          message: this.translateTitle("请输入金额"),
          type: "warning",
          customClass: "zZindex",
        });
        return;
      }
      let url = await this.generateUrl(
        this.radioCurr,
        this.coinCurr.item_name.toUpperCase(),
        this.spendValue,
        this.currencyCurr.logic_name.toUpperCase(),
        this.addressCurr
      );
      window.open(url);
    },
    async generateUrl(paymentCode, symbol, amount, currency, address) {
      let resultUrl = "";
      if (paymentCode.toLowerCase() != "wyre") {
        const { status, data: dataUrl } = await apiFetchOtcUrl({
          payment_code: paymentCode.toLowerCase(),
          symbol: symbol,
          amount: amount,
          currency: currency,
          address: address,
        });
        if (status == 200) {
          resultUrl = dataUrl;
        }
      } else {
        const destCurrency =
          symbol.toUpperCase() == "BTC"
            ? "bitcoin:" + address
            : "ethereum:" + address;
        const { status, data } = await apiWyreUrl({
          sourceAmount: amount,
          sourceCurrency: currency,
          destCurrency: symbol,
          dest: destCurrency,
          country: "US",
        });
        if (status == 200) {
          resultUrl = data.url;
        }
      }
      return resultUrl;
    },
  },
};
</script>
<style lang="scss">
// .ar {
//   .markets-content .markets-content-detail .el-table tbody .cell .symbol-row {
//     text-align: right;
//     margin-right: 40px;
//   }
// }
.ar {
  .quickly-buy-coins-content-page
    .quickly-rechange-center
    .quickly-rechange-center-content {
    .purchasing-price-text {
      direction: rtl;
      .step {
        margin-left: 6.6px;
      }
    }
    .checkbox-out .statement-checkbox {
      direction: rtl;
    }
    .statement {
      direction: rtl;
    }
  }
  .quickly-buy-coins-content-page {
    .recharge-content {
      direction: rtl;
      .left-content {
        .step {
          margin-right: 0;
          margin-left: 6.6px;
        }
      }
      .right-content {
        margin-left: 0;
        margin-right: 60px;
      }
    }
    .bottom-content .purchasing-price-text {
      direction: rtl;

      .step {
        margin-right: 0;
        margin-left: 6.6px;
      }
    }
    .quickly-rechange-footer .quickly-rechange-footer-content {
      direction: rtl;
    }
  }
}
.quickly-buy-coins-content-page {
  .quickly-rechange-header {
    width: 100%;
    height: 56px;
    background-color: #0d0e0f;
    .quickly-rechange-header-content {
      width: 900px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // 左侧
      .left-pages {
        margin-left: 0;
        display: flex;
        align-items: center;

        .pages-routes {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          li {
            color: #fff;
            height: 56px;
            font-weight: 400;
            line-height: 56px;
            margin-right: 50px;
            box-sizing: border-box;
            cursor: pointer;

            a {
              color: #fff;
              opacity: 0.6;
              font-size: 18px;
              font-weight: 500;
              text-decoration: none;
              display: inline-block;
              height: 100%;
            }
            &:hover {
              // font-weight: 500;
              color: #fff;
              a {
                color: #fff;
                opacity: 1;
              }
            }
            &.active {
              border-bottom: 3px solid #15bfb5;
              color: #fff;
              a {
                opacity: 1;
                color: #fff;
              }
            }
          }
        }
      }
      // 右侧
      .right-pages {
        margin-right: 0;
        display: flex;
        align-items: center;

        .pages-routes {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          li {
            color: #fff;
            height: 56px;
            font-weight: 400;
            line-height: 56px;
            margin-right: 26.5px;
            box-sizing: border-box;
            cursor: pointer;

            i {
              color: #fff;
              opacity: 1;
              font-size: 14px;
              font-weight: 500;
              text-decoration: none;
              display: inline-block;
              height: 100%;
            }
            span {
              margin-left: 7.5px;
              color: #fff;
              opacity: 1;
              font-size: 14px;
              font-weight: 500;
              text-decoration: none;
              display: inline-block;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .quickly-rechange-footer {
    width: 100%;
    background-color: #fff;
    .quickly-rechange-footer-content {
      width: 900px;
      margin: 0 auto;
      padding: 36px 0 44.5px;
      .questions {
        font-size: 20px;
        font-weight: 500;
        color: #333;
      }
      .question {
        margin-top: 21.5px;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        span {
          color: #15bfb5;
        }
      }
      .answer {
        margin-top: 7.2px;
        font-size: 14px;
        color: #333;
      }
      .questions-more {
        display: inline-block;
        margin: 28.6px 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #15bfb5;
      }
    }
  }
  .quickly-rechange-center {
    width: 100%;
    // background-color: rgba(73, 222, 255, 0);
    padding: 10px 0 30px;
    background: url("../../assets/img/quickly-coins/quicklycoins-bg.png") 0 0
      no-repeat;
    background-size: 100% 100%;
    .quickly-rechange-center-content {
      width: 900px;
      margin: 0 auto;
      .purchasing-price-text {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500;
        color: #000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .step {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          margin-right: 6.6px;
          background-color: #15bfb5;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .reflush {
          margin-left: 9.5px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .purchasing-price-do {
        width: 900px;
        // height: 140px;
        margin: 15px auto 0;
        padding: 29.2px 24.8px 26px 25.9px;
        border-radius: 6px;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
      }
      .first {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .coin {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          .coin-suffix {
            font-size: 14px;
            font-weight: 500;
            color: #333;
          }
          .coin-input {
            margin-top: 8.8px;
            width: 387.7px;
            height: 56px;
            border-radius: 2px;
            border: solid 1px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            .coin-el-input {
              width: 260px;
              flex-shrink: 0;
              .el-input__inner {
                border: 0px;
                height: 100%;
                line-height: 100%;
                font-family: DINPro-Bold;
                font-size: 22px;
                font-weight: 500;
                color: #333;
                &::placeholder {
                  font-family: DINPro-Regular;
                  font-weight: 400;
                  font-size: 20px;
                }
              }
            }
            .is-disabled .el-input__inner {
              background-color: #fff;
              color: #333;
              cursor: not-allowed;
            }
            .splite {
              width: 1px;
              height: 30.9px;
              background-color: rgba(0, 0, 0, 0.1);
            }
            .el-select {
              background-size: 26px 26px !important;
              .el-input__inner {
                padding-left: 42.4px;
                background-color: transparent;
                border: 0px;
                height: 54px;
                color: #000;
                font-size: 18px;
                font-weight: 500;
              }
              .el-select-dropdown__item {
                height: 50px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .convert {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: solid 1px #8eeeb4;
          background-color: #d8ffe7;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #15bf6d;
          margin-bottom: 8.4px;
          align-self: flex-end;
          .iconsize {
            font-size: 23px;
          }
        }
      }
      .second {
        // height: 167.5px;
        padding: 0;
        table {
          table-layout: fixed;
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          border: 0px solid rgba(0, 0, 0, 0.06);
        }

        table td {
          width: 449.5px;
          height: 83.5px;
          border-top: 0;
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          border-left: 0;
        }

        table tr.lastrow td {
          border-bottom: 0;
        }

        table tr td.lastCol {
          border-right: 0;
        }
        .active {
          background-color: #f2f3f3;
        }
        .payment-channel {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .channel-radio {
              margin: 0 20px 0 20px;
              width: 20px;
              height: 20px;
              // .el-radio__inner {
              //   width: 20px;
              //   height: 20px;
              // }
            }

            .channel-logo {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              background-color: #f2f3f3;
            }
            .name-realprice {
              margin-left: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              .name {
                font-size: 18px;
                font-weight: 500;
                color: #333;
              }
              .realprice {
                margin-top: 4.2px;
                font-size: 11px;
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 21.4px;
            .channel-sub {
              width: 34.6px;
              height: 20px;
              margin-left: 9.4px;
            }
            .channel-sub-more {
              flex-shrink: 0;
              text-align: center;
              width: 34.6px;
              height: 20px;
              margin-left: 9.4px;
              border: solid 0.6px rgba(0, 0, 0, 0.1);
              color: #afafaf;
              font-weight: 900;
              font-size: 17px;
              line-height: 17px;
            }
          }
        }
      }
      .third {
        height: 110.8px;
        padding: 0;
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .details-name {
            font-size: 14px;
            font-weight: 500;
            color: #333;
          }
          .service-provider {
            display: flex;
            align-items: center;
            .pic {
              width: 20px;
              height: 20px;
            }
            .name {
              margin-left: 10.7px;
              font-size: 16px;
              font-weight: 500;
              color: #000;
            }
          }
        }
        table {
          table-layout: fixed;
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          border: 0px solid rgba(0, 0, 0, 0.06);
        }

        table td {
          width: 449.5px;
          height: 54.9px;
          border-top: 0;
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          border-left: 0;
          padding: 0 20.4px 0 28.8px;
        }

        table tr.lastrow td {
          border-bottom: 0;
        }

        table tr td.lastCol {
          border-right: 0;
        }
      }
      .statement {
        width: 687px;
        margin: 23px auto 0;
        font-size: 12px;
        color: #333;
      }
      .checkbox-out {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .statement-checkbox {
          margin: 24px auto 0;
          .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #15bfb5 !important;
            border-color: #15bfb5 !important;
          }
        }
      }
      .confirm {
        cursor: pointer;
        margin: 18.5px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 48px;
        border-radius: 61px;
        background-color: #d6d6d6;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
</style>
