<template>
  <div class="uc-coupon-center">
    <div class="header">
      <div class="content-title">
        <h2>{{ translateTitle("卡券中心") }}</h2>
        <slot name="view"></slot>
      </div>
      <div class="tab-content">
        <ul>
          <li
            class="classify-area"
            :class="{ active: currIndex == item.value }"
            v-for="item of tabList"
            :key="item.value"
            @click="onAreaChange(item)"
          >
            {{ translateTitle(item.label) }}
            <i v-if="currIndex == item.value" class="item-bar"></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="coupon-content-v1" v-loading="tableLoading">
      <div class="coupon-content" v-if="dataListComputed.length > 0">
        <div
          class="coupon-item"
          v-for="item in dataListComputed"
          :key="item.uv_id"
          :class="'status_' + item.status"
        >
          <div class="left">
            <span class="num">{{ item.amount }}</span>
            <span class="unit">{{ item.item_name }}</span>
          </div>
          <div class="right">
            <span class="right-content">
              <p class="name">
                {{ item.product_name }}
              </p>
              <p class="date">
                {{ translateTitle("失效日期") }} {{ timestamp(item.expire) }}
              </p>
              <p class="type" v-if="item.relation_name">
                {{ item.relation_name }}
              </p>
              <p class="type" v-else>
                {{ translateTitle("理财通用券") }}
              </p>
            </span>
            <span
              class="right-btn"
              v-if="item.status == 1"
              @click="goToUse(item)"
            >
              {{ translateTitle("立即使用") }}
            </span>
            <span class="right-btn v2" v-if="item.status == 2">
              {{ translateTitle("已使用") }}
            </span>
            <span class="right-btn v2" v-if="item.status == 3">
              {{ translateTitle("已过期") }}
            </span>
          </div>
        </div>
      </div>
      <no-data
        v-show="dataListComputed.length == 0 && tableLoading == false"
        slot="empty"
      ></no-data>
    </div>
  </div>
</template>

<script>
import { apiGetUserVoucherList } from "@/model/ucenter/couponCenter";
import { translateTitle } from "@/assets/js/i18n";
import { apiGetStaticTableInfo } from "@/model/settings";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import { timestamp } from "@/assets/js/formatDate";
import NoData from "@/components/common/NoData";
export default {
  components: {
    NoData,
  },
  data() {
    return {
      currIndex: 1,
      tableLoading: true,
      dataList: [],
      coinInfoMap: {},
      tabList: [
        {
          value: "1",
          label: "全部",
        },
        // {
        //   value: "2",
        //   label: "理财体验劵",
        // },
        {
          value: "3",
          label: "已失效",
        },
      ],
      finance_tab: "",
    };
  },
  async created() {
    this.finance_tab =
      sessionStorage.getItem("hidden") &&
      JSON.parse(sessionStorage.getItem("hidden"))["finance_tab"]
        ? JSON.parse(sessionStorage.getItem("hidden"))["finance_tab"]
        : "0";
    if (this.finance_tab == 1) {
      this.tabList = [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "3",
          label: "已失效",
        },
      ];
    }
    await this.getCoinInfoMap();
    this.getUserVoucherList();
  },
  computed: {
    dataListComputed() {
      let tempArr = [];
      if (this.currIndex == 1) {
        // tempArr = this.dataList;
        tempArr = this.dataList.filter(ele => {
          return ele.voucher_type == 1;
        });
      } else if (this.currIndex == 2) {
        tempArr = this.dataList.filter(ele => {
          return ele.status == 1 && ele.voucher_type == 1;
        });
      } else if (this.currIndex == 3) {
        tempArr = this.dataList.filter(ele => {
          return (ele.status == 2 || ele.status == 3) && ele.voucher_type == 1;
        });
      }
      return tempArr;
    },
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    timestamp,
    translateTitle,
    goToUse(item) {
      this.$router.push({
        // path: "/finances",
        path: this.routeSplicing(this.language, `finances`),
        query: {
          relation_id: item.relation_id,
        },
      });
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    async getCoinInfoMap() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    getUserVoucherList() {
      let params = {
        voucher_type: 0,
      };
      this.tableLoading = true;
      apiGetUserVoucherList().then(res => {
        if (res.status == 200) {
          let tempArr = res.data.list;
          tempArr.forEach(ele => {
            for (const key in this.coinInfoMap) {
              if (ele.item_id == key) {
                ele.item_name = this.coinInfoMap[key].item_name.toUpperCase();
              }
            }
          });
          this.tableLoading = false;
          this.dataList = tempArr;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.uc-coupon-center {
  .header {
    // height: calc(100vh - 301px);
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    .content-title {
      background-color: #fff;
      position: relative;
      display: flex;
      //   box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
      align-items: center;
      background-color: #ffffff;
      user-select: none;
      // border-radius: 4px;
      h2 {
        font-size: 26px;
        font-weight: 600;
        color: #354052;
        text-align: left;
        height: 60px;
        line-height: 80px;
        padding-left: 24px;
        // padding: 21px 0 21px 24px;
      }
    }
    .tab-content {
      padding-left: 15px;
      background-color: #fff;
      ul {
        display: flex;
      }
      .classify-area {
        padding: 10px 15px;
        cursor: pointer;
        font-size: 16px;
        // height: 32px;
        line-height: 32px;
        color: #354052;
        position: relative;
        user-select: none;
        &.active {
          font-weight: 500;
          background-color: #ffffff;
          color: #354052;
        }
        .item-bar {
          position: absolute;
          width: 70%;
          height: 3px;
          background-color: #e81b40;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
  .coupon-content-v1 {
    /* min-height: calc(100vh - 393px); */
    min-height: calc(100vh - 175px);
    .no-data-box {
      margin: 20px;
      /* min-height: calc(100vh - 413px); */
      min-height: calc(100vh - 175px);
    }
    .coupon-content {
      padding: 20px 20px 20px 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      .coupon-item {
        cursor: pointer;
        min-width: 630px;
        width: calc(50% - 10px);
        height: 130px;
        margin: 0 0 20px 0;
        padding: 23px 20px 22px 0px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        display: flex;
        &.status_2,
        &.status_3 {
          cursor: auto;
          .left {
            .num {
              color: #c2c7d0;
            }
            .unit {
              color: #c2c7d0;
            }
          }
          .right {
            .right-content {
              .name {
                color: #c2c7d0;
              }
              .date {
                color: #c2c7d0;
              }
              .type {
                color: #c2c7d0;
              }
            }
          }
        }

        &:nth-child(2n + 1) {
          margin-right: 20px;
        }
        .left {
          display: inline-block;
          /* margin-right: 30px;
          width: 43px; */
          width: 110px;
          display: flex;
          flex-direction: column;
          /* justify-content: space-between; */
          align-items: center;
          span {
            display: block;
          }
          .num {
            font-size: 24px;
            font-weight: bold;
            color: #f04a5a;
          }
          .unit {
            font-size: 12px;
            font-weight: 500;
            color: #354052;
          }
        }
        .right {
          vertical-align: middle;
          display: inline-block;
          min-width: 500px;
          /* width: calc(100% - 73px); */
          width: calc(100% - 110px);
          height: 85px;
          padding-left: 20px;
          /* border-left: solid 0.5px #c2c7d0; */
          border-left: dashed 0.5px #c2c7d0;
          span {
            display: inline-block;
          }
          .right-content {
            .name {
              font-size: 18px;
              font-weight: 500;
              color: #354052;
            }
            .date {
              color: #9aa1b1;
              font-size: 14px;
              margin: 5px 0 10px;
            }
            .type {
              width: fit-content;
              height: 24px;
              padding: 4px 10px 5px;
              border-radius: 12px;
              background-color: #f4f5f7;
              color: #9aa1b1;
              font-size: 12px;
            }
          }
          .right-btn {
            cursor: pointer;
            height: 32px;
            line-height: 10px;
            padding: 10px 4px;
            border-radius: 3px;
            border: solid 1px #d60c30;
            margin-top: 26px;
            float: right;
            color: #d60c30;
            font-size: 14px;
            font-weight: 500;
            width: 82px;
            user-select: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            &.v2 {
              text-align: center;
              border: none;
              color: #c2c7d0;
              background-color: #ecedf1;
            }
          }
        }
      }
    }
  }
}
</style>
