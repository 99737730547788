<template>
  <div class="uc-transaction-managment">
    <div class="trade-container">
      <div class="header">
        <!-- <top-title :title="translateTitle('金融理财')"> </top-title> -->
        <div class="content-title">
          <h2>{{ translateTitle("交易管理") }}</h2>
          <slot name="view"></slot>
        </div>
        <div class="tab-content">
          <ul>
            <li
              class="classify-area"
              :class="{ active: currIndex == item.value }"
              v-for="item of tabList"
              :key="item.value"
              @click="onAreaChange(item)"
            >
              {{ translateTitle(item.label) }}
              <i v-if="currIndex == item.value" class="item-bar"></i>
            </li>
          </ul>
        </div>
        <div class="count-content" v-if="currIndex == 1">
          <div class="count-item">
            <p class="item-name">{{ translateTitle("持仓估值") }}</p>
            <p class="num DINPro">
              {{ assetsShowCurrency.usdt || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ assetsShowCurrency[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("当前盈亏") }}</p>
            <p class="num DINPro">
              {{ current_profit.usdt || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ current_profit[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("盈亏率") }}</p>
            <p class="num DINPro">
              {{ Number(profit_rate) > 0 ? "+" : "" }}{{ profit_rate || "--" }}
              <span>%</span>
            </p>
            <p style="height: 20px"></p>
          </div>
        </div>
        <div class="count-content" v-if="currIndex == 2">
          <div class="count-item">
            <p class="item-name">{{ translateTitle("持仓估值") }}</p>
            <p class="num DINPro">
              {{ assetsShowCurrency.usd || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ assetsShowCurrency[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("当前盈亏") }}</p>
            <p class="num DINPro">
              {{ current_profit.usdt || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ current_profit[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("盈亏率") }}</p>
            <p class="num DINPro">
              {{ Number(profit_rate) > 0 ? "+" : "" }}{{ profit_rate || "--" }}
              <span>%</span>
            </p>
            <p style="height: 20px"></p>
          </div>
        </div>
        <div class="count-content" v-if="currIndex == 3">
          <div class="count-item">
            <p class="item-name">{{ translateTitle("总资产估值") }}</p>
            <p class="num DINPro">
              {{ assetsShowCurrency.usdt || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ assetsShowCurrency[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
        </div>

        <record v-if="currIndex == 1"></record>
        <record-delivery v-if="currIndex == 2"></record-delivery>
        <record-exchange v-if="currIndex == 3"></record-exchange>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetWebAssets } from "@/model/exchange/index";
import Record from "./components/transaction/record.vue";
import RecordExchange from "./components/transaction/recordExchange.vue";
import RecordDelivery from "./components/transaction/recordDelivery.vue";
import { paddingNumberRight } from "@/assets/js/digital";
import commonMixin from "@/components/common/commonMixin.vue";
import { WEB_OPEN_COINM } from "@/assets/js/stationSetting";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import { mapGetters } from "vuex";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    Record,
    RecordExchange,
    RecordDelivery,
  },
  data() {
    return {
      current_profit: "",
      profit_rate: "",
      WEB_OPEN_COINM,
      assetsShowCurrency: {
        aed: "--",
        cny: "--",
        cnye: "--",
        hkd: "--",
        jpy: "--",
        krw: "--",
        usd: "--",
        usdt: "--",
      },
      active: 1,
      currIndex: 3,
      tabList: [
        // {
        //   value: "3",
        //   label: "币币交易"
        // },
        // {
        //   value: "1",
        //   label: "U本位合约"
        // },
        // {
        //   value: "2",
        //   label: "币本位合约"
        // }
        // {
        //   value: "4",
        //   label: "股票交易",
        // },
        // {
        //   value: "5",
        //   label: "超级杠杆",
        // },
      ],
      trade_tab: "",
    };
  },

  async created() {
    this.trade_tab =
      sessionStorage.getItem("hidden") &&
      JSON.parse(sessionStorage.getItem("hidden"))["trade_tab"]
        ? JSON.parse(sessionStorage.getItem("hidden"))["trade_tab"]
        : "0";
    if (this.isHideLever || this.trade_tab == 1) {
      this.tabList = [
        {
          value: "3",
          label: "币币交易",
        },
        // {
        //   value: "1",
        //   label: "U本位合约"
        // },
        // {
        //   value: "2",
        //   label: "币本位合约"
        // }
      ];
    } else if (WEB_OPEN_COINM != 1) {
      this.tabList = [
        {
          value: "3",
          label: "币币交易",
        },
        // {
        //   value: "1",
        //   label: "U本位合约",
        // },
      ];
    } else {
      this.tabList = [
        {
          value: "3",
          label: "币币交易",
        },
        // {
        //   value: "1",
        //   label: "U本位合约",
        // },
        // {
        //   value: "2",
        //   label: "币本位合约",
        // },
      ];
    }
    // 交易管理开始
    this.getWebAssets();
    this.startAssetsTimer();

    // 交易管理结束
  },
  destroyed() {},
  beforeDestroy() {},
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
  },
  methods: {
    // 交易管理开始
    startAssetsTimer() {
      this.getWebAssets().then(() => {
        const timer = setTimeout(() => {
          this.startAssetsTimer();
        }, 6000);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },
    async getWebAssets() {
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetWebAssets(params);
      if (status == 200) {
        // this.contractMap = data.contract;
        if (this.currIndex == 1) {
          this.assetsShowCurrency = data.contract.all_u_valuation;
          this.current_profit = data.contract.all_current_profit;
          this.profit_rate = paddingNumberRight(data.contract.profit_rate, 2);
        } else if (this.currIndex == 2) {
          this.assetsShowCurrency = data.future.all_u_valuation;
          this.current_profit = data.future.all_current_profit;
          this.profit_rate = paddingNumberRight(data.future.profit_rate, 2);
        } else if (this.currIndex == 3) {
          this.assetsShowCurrency = data.remain.all_u_valuation;
        }
      }
    },
    onAreaChange(item) {
      this.currIndex = item.value;
      this.getWebAssets();
    },
    // 交易管理结束
  },
};
</script>
<style lang="scss">
.uc-transaction-managment {
  .finance-dialog {
    width: 1000px;
    // height: 680px;
    &.active3 {
      height: 475px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      border-radius: 8px;
      justify-content: space-between;
    }
  }
  // .confirm-dialog {
  //   .el-dialog__header {
  //     font-size: 20px;
  //     font-weight: 500;
  //     padding: 20px 30px 10px;
  //   }
  //   .el-dialog__body {
  //     padding: 10px 30px 30px;
  //   }
  // }
  .trade-container {
    .header {
      // height: calc(100vh - 301px);
      border-radius: 4px;
      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        /* box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%); */
        align-items: center;
        background-color: #ffffff;
        user-select: none;
        // border-radius: 4px;
        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 68px;
          line-height: 80px;
          padding-left: 24px;
          // padding: 21px 0 21px 24px;
        }
      }
      .tab-content {
        padding-left: 15px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
        ul {
          display: flex;
        }
        .classify-area {
          padding: 10px 10px;
          cursor: pointer;
          font-size: 16px;
          // height: 32px;
          line-height: 32px;
          color: #354052;
          position: relative;
          &.active {
            font-weight: 500;
            background-color: #ffffff;
            color: #354052;
          }
          .item-bar {
            position: absolute;
            width: 70%;
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
    .count-content {
      // width: 1552px;
      margin: 20px;
      height: 118px;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      background-color: #ffffff;
      .count-item {
        width: 260px;
        color: #9aa1b1;
        display: inline-block;
        overflow: hidden;
        .item-name {
          font-size: 14px;
          user-select: none;
        }
        .num {
          color: #354052;
          font-size: 24px;
          font-weight: bolder;
          margin: 5px 0;
          span {
            font-size: 12px;
          }
        }
        .num2 span {
          font-size: 12px;
        }
      }
    }
    .transaction-managment-content {
      padding: 0px 20px;
      background: #ffffff;
      overflow: hidden;
      margin: 20px;
      min-height: 600px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      .market-header {
        border-bottom: solid 1px #ecedf1;
        margin-bottom: 20px;
        ul {
          display: flex;
        }
        .classify-area {
          height: 40px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #354052;

          padding: 0 10px;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          font-weight: normal;
          position: relative;
          user-select: none;
          &.active {
            font-weight: 500;
          }
          .item-bar {
            position: absolute;
            width: calc(100% - 20px);
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
      .content-header {
        width: 120px;
        height: 40px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
      .el-table {
        // height: calc(100vh - 692px);
        /* min-height: 624px; */
        min-height: 570px;
      }
      .no-data-box {
        /* min-height: 600px; */
        min-height: 520px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          // display: flex;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .filter-box {
        padding: 20px 0px 15px 0px;
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
      }
      .operation {
        font-size: 14px;
        color: #3a7dff;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
    }
  }
  .holder-detail-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 0;
    }
    .el-dialog__body {
      padding: 20px 30px 30px;
    }
    .first-content-1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      overflow: hidden;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 180px;
        display: inline-block;
        vertical-align: middle;
        user-select: none;
      }
      .schedule {
        height: 20px;
        line-height: 20px;
        padding: 0px 5px;
        vertical-align: middle;
        color: white;
        font-size: 12px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: inline-block;
        margin-right: 10px;
        // background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
        &.accruing {
          background: rgb(196, 196, 198);
        }
        &.warming {
          background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        }
        &.recruit {
          background-image: linear-gradient(259deg, #ff486d 20%, #c61b3e 140%);
        }
        &.storage {
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
        }
        &.issuing {
          background: rgb(196, 196, 198);
        }
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        margin-left: 38px;
      }
      .number {
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
      }
    }
    .first-content-2-3 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 350px;
        display: inline-block;
        vertical-align: middle;
      }
      .first-type-1 {
        height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-radius: 4.4px;
        vertical-align: text-top;
        background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-left: 38px;
      }
      .number {
        font-size: 24px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        text-align: right;
      }
    }
    .first-content-4 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #354052;
        max-width: 200px;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
      }
      .first-type-1 {
        height: 20px;
        line-height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-radius: 4.4px;
        vertical-align: middle;
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        margin-left: 38px;
      }
      .number {
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
        &.V2 {
          text-align: left;
        }
      }
    }
    .second-content {
      overflow: hidden;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      .total-rate {
        width: px2rem(590);
        margin: px2rem(28) auto 0;
        .total-num-box {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          height: 15px;
          line-height: 15px;
          font-weight: 500;
          .total-num {
            color: #9fa2aa;
          }
          .already-num {
            color: #15d39a;
          }
        }
        .total-line-box {
          width: 100%;
          height: 6px;
          border-radius: 5px;
          background-color: #e2e2e4;
          margin-top: 8px;
          i {
            display: block;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            width: 60%;
          }
        }
      }
    }
    .four-content {
      margin-bottom: 20px;
      .step-graph {
        position: relative;
        height: 18px;
        .step-circle {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 0;
          &.step-circle1 {
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
            left: 0;
          }
          &.step-circle2 {
            left: 50%;
            margin-left: -9px;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.step-circle3 {
            right: 0;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.active {
            background: url("~@/assets/img/financial-detail/icon-2@3x.png")
              no-repeat;
            background-size: 100%;
          }
        }
        .step-line {
          position: absolute;
          width: 196px;
          height: 2px;
          border-radius: 2px;
          background-color: #e2e2e4;
          top: 8px;
          overflow: hidden;
          i {
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            background: #e2e2e4;
          }
          &.active {
            // background-color: #15d39a;
            i {
              background-color: #15d39a;
            }
          }
          &.step-line1 {
            left: 18px;
          }
          &.step-line2 {
            right: 18px;
          }
        }
      }
      .step-text-box {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .step-block {
          font-size: 12px;
          width: 100%;
          white-space: nowrap;
          .step-text {
            display: block;
            color: #354052;
            height: 15px;
            line-height: 15px;
          }
          .step-time {
            display: block;
            color: #9fa2aa;
            height: 15px;
            line-height: 15px;
            margin-top: 2px;
          }
          &.step-block1 {
            text-align: left;
          }
          &.step-block2 {
            text-align: center;
          }
          &.step-block3 {
            text-align: right;
          }
        }
      }
    }
    .info-content {
      width: 440px;
      height: 120px;
      margin: 10px 5px 19px 0;
      padding: 10px 15px 12px;
      border-radius: 4px;
      background-color: #f3f7fc;
      .line-item {
        display: flex;
        justify-content: space-between;
        color: #9aa1b1;
        font-size: 14px;
        line-height: 1.8;
        user-select: none;
        .next {
          user-select: text;
          max-width: 278px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .record-list {
      height: 200px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .common-btn {
      &.btn {
        width: 300px;
        display: block;
        text-align: center;
        margin: 20px auto 10px;
        cursor: pointer;
        user-select: none;
      }
    }
    .nav {
      display: flex;
      flex: 1;
      justify-content: space-between;
      font-size: 14px;
      .list-type {
        .tab {
          user-select: none;
          width: fit-content;
          height: 32px;
          line-height: 32px;
          margin-right: 10px;
          text-align: center;
          display: inline-block;
          padding: 0px 16px;
          background: #f1f5f9;
          border-radius: 17px;
          border: solid 1px #f1f5f9;
          cursor: pointer;
          span {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            color: #354052;
            &:after {
              content: "";
              height: 2px;
              width: 80%;
              background-color: #354052;
            }
          }
          &.active {
            background-color: white;
            color: #c61b3d;
            border: solid 1px #c61b3d;
            span {
              color: #c61b3d;
            }
          }
        }
      }
    }
    .list-content {
      .list-item {
        width: 440px;
        height: 50px;
        margin: 15px 5px 15px 0;
        padding: 7px 20px 8px 15px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 3px 5px 0 rgba(53, 64, 82, 0.04);
        background-color: #ffffff;
        .left {
          user-select: none;
          span {
            display: block;
          }
          .title {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
          }
          .time {
            color: #c2c7d0;
            font-size: 12px;
            font-weight: normal;
          }
        }
        .right {
          line-height: 35px;
          &.green {
            color: #28ba98;
          }
          &.red {
            color: #f04a5a;
          }
        }
      }
    }
  }
  .take-out-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px;
    }
    .el-dialog__body {
      padding: 0px 30px 20px;
    }
    .take-out-dialog-content {
      .first-content {
        display: flex;
        justify-content: space-between;
        .left-content {
          img {
            width: 26px;
            height: 26px;
            display: inline-block;
          }
          .product-name {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            max-width: 220px;
            .top {
              font-size: 18px;
              font-weight: 500;
              color: #354052;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 146px;
              display: inline-block;
              vertical-align: middle;
            }

            .schedule {
              height: 24px;
              line-height: 20px;
              padding: 2px 5px;
              color: white;
              font-size: 12px;
              border-top-left-radius: 5px;
              border-bottom-right-radius: 5px;
              vertical-align: middle;
              margin-left: 5px;
              display: inline-block;
              margin-right: 10px;
              &.accruing {
                background: rgb(196, 196, 198);
              }
              &.warming {
                background-image: linear-gradient(
                  to right,
                  #f78700 0%,
                  #ffb152 100%
                );
              }
              &.recruit {
                background-image: linear-gradient(
                  259deg,
                  #ff486d 20%,
                  #c61b3e 140%
                );
              }
              &.storage {
                background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
              }
              &.issuing {
                background: rgb(196, 196, 198);
              }
            }
            .bottom {
              margin-top: 5px;
              font-size: 14px;
              color: #354052;
            }
          }
        }
        .right-content {
          .red-test {
            font-size: 18px;
            font-weight: bold;
            color: #f04a5a;
            text-align: right;
            user-select: text;
          }
          .bottom-text {
            margin-top: 5px;
            font-size: 14px;
            color: #354052;
          }
        }
      }
      .second-content {
        margin-top: 20px;
        .content-text {
          display: flex;
          justify-content: space-between;
          .left {
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
          .right {
            font-size: 14px;
            text-align: right;
            color: #354052;
          }
        }
        .input-content {
          margin-top: 7px;
          position: relative;
          .el-input__suffix {
            line-height: 40px;
            margin-right: 40px;
            color: #354052;
            .el-input__suffix-inner {
              font-size: 12px;
            }
          }
          .all-btn {
            position: absolute;
            right: 15px;
            top: 12px;
            font-size: 12px;
          }
        }
      }
      .item-content {
        .tip-box {
          background-color: #f3f7fc;
          margin: 0 auto;
          margin-top: 10px;
          padding: 10px 15px;
          position: relative;
          &:before {
            content: "";
            width: 200%;
            height: 200%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.5);
            box-sizing: border-box;
          }
          .line-item {
            display: flex;
            line-height: 24px;
            justify-content: space-between;
            color: #9aa1b1;
            font-size: 14px;
            .next {
              max-width: 200px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      .common-dialog-footer {
        margin-top: 28px;
      }
    }
  }
}
</style>
